/* Add blur to the form when 'blur-effect' class is present */
.blur-effect {
    // filter: blur(2px); /* You can adjust the blur intensity (2px in this case) as needed */
    pointer-events: none;
    /* Prevent interactions with the form while blurred */
    // opacity: 0.6; /* You can adjust the opacity as needed for the blur effect */
}

.width-200-px {
    min-width: 200px;
    max-width: 200px;
}

.border-right-width-4 {
    border-right-width: 4px !important;
}

.border-right-dark {
    border-right-color: black !important;
}