.visually-hidden{
    visibility: hidden;
}

.spinner-container{
    width: 100%;
    text-align: center;
    height:100%;
    justify-content: center;
    align-items: center;
    flex: 1 1 auto;
}