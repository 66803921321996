$GIB_BLUE_COLOR : rgb(44, 169, 214);

// Here you can add other styles
.header>.container-fluid,
.sidebar-header {
  min-height: calc(4rem + 1px); // stylelint-disable-line function-disallowed-list
}

.sidebar-brand-full {
  margin-left: 3px;
}

.sidebar-header {
  .nav-underline-border {
    --cui-nav-underline-border-link-padding-x: 1rem;
    --cui-nav-underline-border-gap: 0;
  }

  .nav-link {
    display: flex;
    align-items: center;
    min-height: calc(4rem + 1px); // stylelint-disable-line function-disallowed-list
  }
}

.sidebar-toggler {
  @include ltr-rtl("margin-left", auto);
}

.sidebar-narrow,
.sidebar-narrow-unfoldable:not(:hover) {
  .sidebar-toggler {
    @include ltr-rtl("margin-right", auto);
  }
}

.header>.container-fluid+.container-fluid {
  min-height: 1rem;
}

.header {
  padding: 0;

  .container-fluid {
    padding-right: $spacer;
    padding-left: $spacer;
  }
}

.footer {
  min-height: calc(3rem + 1px); // stylelint-disable-line function-disallowed-list
}


// Here you can add other styles
// body {
//   margin: 0 !important;
//   font-family: -apple-system,
//       BlinkMacSystemFont, "Segoe UI", Roboto,
//       "Helvetica Neue", Arial, "Noto Sans", sans-serif,
//       "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
//       "Noto Color Emoji" !important;
//   font-size: 0.8rem !important;
//   font-weight: 400 !important;
//   line-height: 1.5 !important;
//   color: #23282c !important;
//   text-align: left !important;
//   background-color: #e4e5e6 !important;
// }

// @import "~bootstrap/scss/_type";

// h1, h2, h3, h4, h5, h6,
// .h1, .h2, .h3, .h4, .h5, .h6 {
// margin-bottom: $headings-margin-bottom !important;
// font-family: $headings-font-family !important;
// font-weight: $headings-font-weight !important;
// line-height: $headings-line-height !important;
// color: $headings-color !important;
// }
p {
  // margin: none !important;
  margin-top: 0 !important;
  margin-bottom: 1rem !important;
}

h1,
.h1 {
  font-size: 36px !important;
}

h2,
.h2 {
  font-size: 30px !important;
}

h3,
.h3 {
  font-size: 24px !important;
}

h4,
.h4 {
  font-size: 18px !important;
}

h5,
.h5 {
  font-size: 16px !important;
}

h6,
.h6 {
  font-size: 14px !important;
}

.col-auto,
.col-sm-auto,
.col-md-auto,
.col-lg-auto,
.col-xl-auto {
  width: auto !important;
}

@media only screen and (min-width: '992px') {
  .sidebar-fixed .app-header+.app-body .sidebar {
    height: calc(100vh - 55px) !important;
    padding-top: 1.6rem !important;
  }

  .aside-menu-off-canvas .app-header+.app-body .aside-menu {
    height: calc(100vh - 85px) !important;
    padding-top: 1.6rem !important;
  }

  .sidebar-lg-show.sidebar-fixed .main,
  html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed .app-footer,
  html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .main,
  html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .app-footer {
    margin-left: 200px;
    margin-top: 1.8rem;
  }

  .nav-tabs {
    border-bottom: 1px solid #c8ced3;
    border-top: 1px solid #c8ced3;
  }

  .app-body .main {
    flex: 1 1;
    min-width: 0;
    margin-top: 1.8rem;
  }
}

@media only screen and (min-width: 992px) {
  .sidebar-minimized .sidebar .nav>.nav-dropdown>.nav-dropdown-items {
    display: none;
    max-height: 200px;
    background: #2f353a;
    overflow-y: auto !important;
  }
}

//Custom Range Picker Design
:where(.css-dev-only-do-not-override-ed5zg0).ant-picker-range .ant-picker-range-separator {
  align-items: center;
  padding: 0 0px;
  line-height: 1;
}

.card {
  margin-bottom: 5px;
}

.custom-tab {
  background-color: #f1f1f1;
  /* Light gray background for unselected tabs */
  color: #000;
  /* Black text */
  // padding: 10px;
  border-radius: 4px 4px 0 0;
  // margin-right: 5px;
  cursor: pointer;
}

.nav-link.active {
  background-color: $GIB_BLUE_COLOR !important;
  color: white !important;
  position: relative;
  padding-right: 20px;
  /* Adjust padding if necessary */
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  transition: none
}

.nav-link.active::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid $GIB_BLUE_COLOR;
  transition: none
}

.date-preview {
  font-size: 0.8em; /* Adjust the size as needed */
  color: #666; /* Optional: Make the text color a bit lighter */
}
